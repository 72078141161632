var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ny_index"},[(Object.keys(_vm.imgList).length != 0)?_c('Carousel',{staticClass:"zz_Carousel",attrs:{"list":_vm.imgList.lbList}}):_vm._e(),(Object.keys(_vm.imgList).length != 0)?_c('div',{staticClass:"index_various",style:({
      background: `url(${_vm.imgList.imgList[1]}) center no-repeat`
    })},[_c('div',{staticClass:"index_various_nav"},[_c('div',{staticClass:"all_title"},[_c('i',{style:({
            background: `url(${_vm.imgList.imgList[0]}) 0px 0px no-repeat`
          })}),_c('h4',[_vm._v(" "+_vm._s(_vm.basicData.basicList[0].key)+" "),_c('p',[_vm._v(_vm._s(_vm.basicData.basicList[0].value))])])]),_vm._l((_vm.cpList),function(item,idx){return _c('div',{key:idx,staticClass:"nav_qm"},[_c('div',{staticClass:"nav_qm_head",style:({
            background: `url(${item.icon_image}) 100%/100% no-repeat`
          })},[_c('div',[_c('h4',[_vm._v(_vm._s(item.name))]),_c('h4',{staticClass:"h4_name",staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(item.name.slice(0, 3))+" ")]),_c('span',[_vm._v(_vm._s(_vm.basicData.titleList[idx].value))])]),_c('a',{attrs:{"href":'#/ny_product?currentIdx=' + (idx + 1)},on:{"click":function($event){return _vm.toLink(90)}}},[_vm._v("更多")])]),_c('ul',{staticClass:"nav_qm_list"},_vm._l((item.news),function(el,i){return _c('li',{key:i},[_c('a',{attrs:{"href":'#/ny_product?currentIdx=' + (idx + 1) + '&currentId=' + el.id},on:{"click":function($event){return _vm.toLink(90)}}},[_c('img',{attrs:{"src":el.site_images,"alt":""}}),_c('p',{staticClass:"ellipsis"},[_vm._v(_vm._s(el.title))])])])}),0)])})],2)]):_vm._e(),(Object.keys(_vm.imgList).length != 0)?_c('div',{staticClass:"index_section",style:({
      background: `url(${_vm.imgList.imgList[2]}) no-repeat center 0`
    })},[_c('div',{staticClass:"index_section_nav"},[_c('div',{staticClass:"nav_left"},[_c('img',{attrs:{"src":_vm.imgList.imgList[3],"alt":""}})]),_c('div',{staticClass:"nav_right"},[_c('h2',[_vm._v("某某苗圃新闻动态信息")]),_c('horseRace',{attrs:{"list":_vm.allNews,"data":_vm.newData}})],1)])]):_vm._e(),(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"index_advantage"},[_c('div',{staticClass:"all_head",style:({
        background: `url(${_vm.imgList.imgList[4]}) no-repeat center 0`
      })}),_c('div',{staticClass:"index_advantage_nav"},[_c('div',{staticClass:"all_title"},[_c('i',{style:({
            background: `url(${_vm.imgList.imgList[0]}) 0px 0px no-repeat`
          })}),_c('h4',[_c('span',[_vm._v(_vm._s(_vm.basicData.basicList[1].key))]),_c('p',[_vm._v(_vm._s(_vm.basicData.basicList[1].value))])])]),_c('ul',{staticClass:"nav_list"},_vm._l((_vm.ysList),function(item,idx){return _c('li',{key:idx,style:({ backgroundImage: `url(${item.ys_bg})` })},[_c('div',{staticClass:"li_tit"},[_c('img',{attrs:{"src":item.ys_logo,"alt":""}}),_c('span',[_vm._v(_vm._s(item.ys_title))])]),_c('div',{staticClass:"li_text"},_vm._l((item.ys_list),function(el,i){return _c('p',{key:i},[_vm._v(_vm._s(el.value))])}),0)])}),0)])]):_vm._e(),(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"index_nicety"},[_c('div',{staticClass:"all_head",style:({
        background: `url(${_vm.imgList.imgList[5]}) no-repeat center 0`
      })}),_c('div',{staticClass:"index_nicety_nav"},[(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"all_title"},[_c('i',{style:({
            background: `url(${_vm.imgList.imgList[0]}) 0px 0px no-repeat`
          })}),_c('h4',[_vm._v(" "+_vm._s(_vm.basicData.basicList[2].key)+" "),_c('p',[_vm._v(_vm._s(_vm.basicData.basicList[2].value))])])]):_vm._e(),_c('ul',{staticClass:"nav_list"},_vm._l((_vm.xjData.xjList),function(item,idx){return _c('li',{key:idx},[_c('div',{staticClass:"li_img"},[_c('img',{attrs:{"src":_vm.xjData.xjImg[idx],"alt":""}})]),_c('div',{staticClass:"li_text"},[_c('h2',[_vm._v(_vm._s(item.key))]),_c('p',[_vm._v(_vm._s(item.value))])])])}),0)]),_c('div',{staticClass:"index_nicety_case"},[(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"all_title"},[_c('i',{style:({
            background: `url(${_vm.imgList.imgList[0]}) 0px 0px no-repeat`
          })}),_c('h4',[_vm._v(" "+_vm._s(_vm.basicData.basicList[3].key)+" "),_c('p',[_vm._v(_vm._s(_vm.basicData.basicList[3].value))])])]):_vm._e(),_c('ul',{staticClass:"case_head"},_vm._l((_vm.alHead),function(item,idx){return _c('li',{key:idx,on:{"mouseenter":function($event){return _vm.replaceData(item, idx)}}},[_c('a',{style:({ backgroundColor: _vm.li_xz == idx ? '#fb2c3b' : '' }),attrs:{"href":'#/ny_anli?currentIdx=' + idx},on:{"click":function($event){return _vm.toLink(91)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('el-carousel',{attrs:{"interval":5000,"type":"card","height":"400px"}},_vm._l((_vm.alList),function(item){return _c('el-carousel-item',{key:item.id},[_c('a',{staticClass:"item_nav",attrs:{"href":'#/ny_anli?currentIdx=' + _vm.li_xz + '&currentId=' + item.id},on:{"click":function($event){return _vm.toLink(91)}}},[_c('img',{attrs:{"src":item.site_images,"alt":""}}),_c('div',{staticClass:"li_text"},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),1)],1)]):_vm._e(),(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"index_base"},[_c('div',{staticClass:"all_head",style:({
        background: `url(${_vm.imgList.imgList[6]}) no-repeat center 0`
      })}),_c('div',{staticClass:"index_base_nav"},[_c('div',{staticClass:"all_title"},[_c('i',{style:({
            background: `url(${_vm.imgList.imgList[0]}) 0px 0px no-repeat`
          })}),_c('h4',[_vm._v(" "+_vm._s(_vm.basicData.basicList[4].key)+" "),_c('p',[_vm._v(_vm._s(_vm.basicData.basicList[4].value))])])]),_c('div',{staticClass:"news"},[_c('div',{staticClass:"news_img"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.imgList.imgList[7],"alt":""}})]),_c('ul',{staticClass:"news_list"},_vm._l((_vm.newsList),function(item,idx){return _c('li',{key:idx},[_c('div',{staticClass:"li_nav"},[_c('a',{staticClass:"ellipsis",attrs:{"href":'#/ny_news?currentIdx=' + 0 + '&currentId=' + item.id},on:{"click":function($event){return _vm.toLink(92)}}},[_vm._v(_vm._s(item.title))]),_c('h4',[_vm._v(_vm._s(item.create_time))]),_c('p',{staticClass:"two_ellipsis"},[_vm._v(_vm._s(item.miaoshu))])]),_c('a',{staticClass:"li_img",attrs:{"href":'#/ny_news?currentIdx=' + 0 + '&currentId=' + item.id},on:{"click":function($event){return _vm.toLink(92)}}},[_c('img',{attrs:{"src":item.cover_image,"alt":""}})])])}),0)]),_c('div',{staticClass:"aboutUs"},[_c('div',{staticClass:"aboutUs_img"},[_c('img',{attrs:{"src":_vm.imgList.imgList[8],"alt":""}})]),_c('div',{staticClass:"aboutUs_nav"},[_c('div',{staticClass:"aboutUs_nav_head"},[_c('img',{attrs:{"src":_vm.imgList.imgList[9],"alt":""}})]),_c('div',{staticClass:"aboutUs_nav_col"},[_c('p',[_vm._v(" "+_vm._s(_vm.basicData.basicList[5].value)+" ")]),_c('a',{attrs:{"href":"#/ny_aboutuser"},on:{"click":function($event){return _vm.toLink(93)}}},[_vm._v(_vm._s(_vm.basicData.basicList[5].key))])])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }