<template>
  <div class="list_box">
    <div
      id="scroll_box"
      ref="scroll_box"
      class="list anim"
      @mouseenter="stopAn"
      @mouseleave="leaveAn"
    >
      <ul ref="list1">
        <li v-for="(item, i) in list" :key="i">
          <a
            class="name"
            :href="
              '#/' + data.link + '?currentIdx=' + 0 + '&currentId=' + item.id
            "
            @click="toLink(data.id)"
            >{{ item.title }}</a
          >
          <a class="num">{{ item.create_time }}</a>
        </li>
      </ul>
      <ul ref="list2">
        <li
          v-for="(item, i) in list"
          :key="i"
          :class="{ bg: i % 2 == 0 ? true : false }"
        >
          <a
            class="name"
            :href="
              '#/' + data.link + '?currentIdx=' + 0 + '&currentId=' + item.id
            "
            @click="toLink(data.id)"
            >{{ item.title }}</a
          >
          <a class="num">{{ item.create_time }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    },
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {}
  },
  methods: {
    // 接着动画
    leaveAn () {
      this.$refs.list1.style.animationPlayState = 'running'
      this.$refs.list2.style.animationPlayState = 'running'
    },
    // 暂停动画
    stopAn () {
      this.$refs.list1.style.animationPlayState = 'paused'
      this.$refs.list2.style.animationPlayState = 'paused'
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.list_box {
  .anim {
    &:hover {
      cursor: pointer;
    }
  }
  .table_title {
    font-size: 14px;
    color: #757e83;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 55px;
    height: 55px;
    padding: 0 14px;
    box-sizing: border-box;
  }
  .list {
    height: 260px;
    overflow: hidden;
    ul {
      -webkit-animation: 15s move infinite linear; //动画开始
      li {
        color: rgb(51, 51, 51);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        .name {
          font-size: 14px;
          max-width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: keep-all; //名称超出不换行，隐藏
          white-space: nowrap; /* 不换行 */
        }
        .name:hover {
          color: #3da036;
        }
      }
    }

    @-webkit-keyframes move {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(0, -100%);
      }
    }

    @keyframes move {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(0, -100%);
      }
    }
  }
}
</style>
