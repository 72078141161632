<template>
  <!--  -->
  <div class="ny_index">
    <Carousel
      :list="imgList.lbList"
      class="zz_Carousel"
      v-if="Object.keys(imgList).length != 0"
    >
    </Carousel>
    <div
      class="index_various"
      v-if="Object.keys(imgList).length != 0"
      :style="{
        background: `url(${imgList.imgList[1]}) center no-repeat`
      }"
    >
      <div class="index_various_nav">
        <div class="all_title">
          <i
            :style="{
              background: `url(${imgList.imgList[0]}) 0px 0px no-repeat`
            }"
          ></i>
          <h4>
            {{ basicData.basicList[0].key }}
            <p>{{ basicData.basicList[0].value }}</p>
          </h4>
        </div>
        <div class="nav_qm" v-for="(item, idx) in cpList" :key="idx">
          <div
            class="nav_qm_head"
            :style="{
              background: `url(${item.icon_image}) 100%/100% no-repeat`
            }"
          >
            <div>
              <h4>{{ item.name }}</h4>
              <h4 class="h4_name" style="display:none">
                {{ item.name.slice(0, 3) }}
              </h4>
              <span>{{ basicData.titleList[idx].value }}</span>
            </div>
            <a
              :href="'#/ny_product?currentIdx=' + (idx + 1)"
              @click="toLink(90)"
              >更多</a
            >
          </div>
          <ul class="nav_qm_list">
            <li v-for="(el, i) in item.news" :key="i">
              <a
                :href="
                  '#/ny_product?currentIdx=' + (idx + 1) + '&currentId=' + el.id
                "
                @click="toLink(90)"
              >
                <img :src="el.site_images" alt="" />
                <p class="ellipsis">{{ el.title }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="index_section"
      v-if="Object.keys(imgList).length != 0"
      :style="{
        background: `url(${imgList.imgList[2]}) no-repeat center 0`
      }"
    >
      <div class="index_section_nav">
        <div class="nav_left">
          <img :src="imgList.imgList[3]" alt="" />
        </div>
        <div class="nav_right">
          <h2>某某苗圃新闻动态信息</h2>
          <horseRace :list="allNews" :data="newData"></horseRace>
        </div>
      </div>
    </div>
    <div class="index_advantage" v-if="Object.keys(basicData).length != 0">
      <div
        class="all_head"
        :style="{
          background: `url(${imgList.imgList[4]}) no-repeat center 0`
        }"
      ></div>
      <div class="index_advantage_nav">
        <div class="all_title">
          <i
            :style="{
              background: `url(${imgList.imgList[0]}) 0px 0px no-repeat`
            }"
          ></i>
          <h4>
            <span>{{ basicData.basicList[1].key }}</span>
            <p>{{ basicData.basicList[1].value }}</p>
          </h4>
        </div>
        <ul class="nav_list">
          <li
            v-for="(item, idx) in ysList"
            :key="idx"
            :style="{ backgroundImage: `url(${item.ys_bg})` }"
          >
            <div class="li_tit">
              <img :src="item.ys_logo" alt="" />
              <span>{{ item.ys_title }}</span>
            </div>
            <div class="li_text">
              <p v-for="(el, i) in item.ys_list" :key="i">{{ el.value }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="index_nicety" v-if="Object.keys(basicData).length != 0">
      <div
        class="all_head"
        :style="{
          background: `url(${imgList.imgList[5]}) no-repeat center 0`
        }"
      ></div>
      <div class="index_nicety_nav">
        <div class="all_title" v-if="Object.keys(basicData).length != 0">
          <i
            :style="{
              background: `url(${imgList.imgList[0]}) 0px 0px no-repeat`
            }"
          ></i>
          <h4>
            {{ basicData.basicList[2].key }}
            <p>{{ basicData.basicList[2].value }}</p>
          </h4>
        </div>
        <ul class="nav_list">
          <li v-for="(item, idx) in xjData.xjList" :key="idx">
            <div class="li_img">
              <img :src="xjData.xjImg[idx]" alt="" />
            </div>
            <div class="li_text">
              <h2>{{ item.key }}</h2>
              <p>{{ item.value }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="index_nicety_case">
        <div class="all_title" v-if="Object.keys(basicData).length != 0">
          <i
            :style="{
              background: `url(${imgList.imgList[0]}) 0px 0px no-repeat`
            }"
          ></i>
          <h4>
            {{ basicData.basicList[3].key }}
            <p>{{ basicData.basicList[3].value }}</p>
          </h4>
        </div>
        <ul class="case_head">
          <li
            v-for="(item, idx) in alHead"
            :key="idx"
            @mouseenter="replaceData(item, idx)"
          >
            <a
              :href="'#/ny_anli?currentIdx=' + idx"
              @click="toLink(91)"
              :style="{ backgroundColor: li_xz == idx ? '#fb2c3b' : '' }"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
        <el-carousel :interval="5000" type="card" height="400px">
          <el-carousel-item v-for="item in alList" :key="item.id">
            <a
              :href="'#/ny_anli?currentIdx=' + li_xz + '&currentId=' + item.id"
              @click="toLink(91)"
              class="item_nav"
            >
              <img :src="item.site_images" alt="" />
              <div class="li_text">
                {{ item.title }}
              </div>
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="index_base" v-if="Object.keys(basicData).length != 0">
      <div
        class="all_head"
        :style="{
          background: `url(${imgList.imgList[6]}) no-repeat center 0`
        }"
      ></div>
      <div class="index_base_nav">
        <div class="all_title">
          <i
            :style="{
              background: `url(${imgList.imgList[0]}) 0px 0px no-repeat`
            }"
          ></i>
          <h4>
            {{ basicData.basicList[4].key }}
            <p>{{ basicData.basicList[4].value }}</p>
          </h4>
        </div>
        <div class="news">
          <div class="news_img">
            <img :src="imgList.imgList[7]" alt="" style="width:100%" />
          </div>
          <ul class="news_list">
            <li v-for="(item, idx) in newsList" :key="idx">
              <div class="li_nav">
                <a
                  :href="'#/ny_news?currentIdx=' + 0 + '&currentId=' + item.id"
                  @click="toLink(92)"
                  class="ellipsis"
                  >{{ item.title }}</a
                >
                <h4>{{ item.create_time }}</h4>
                <p class="two_ellipsis">{{ item.miaoshu }}</p>
              </div>
              <a
                :href="'#/ny_news?currentIdx=' + 0 + '&currentId=' + item.id"
                @click="toLink(92)"
                class="li_img"
              >
                <img :src="item.cover_image" alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div class="aboutUs">
          <div class="aboutUs_img">
            <img :src="imgList.imgList[8]" alt="" />
          </div>
          <div class="aboutUs_nav">
            <div class="aboutUs_nav_head">
              <img :src="imgList.imgList[9]" alt="" />
            </div>
            <div class="aboutUs_nav_col">
              <p>
                {{ basicData.basicList[5].value }}
              </p>
              <a href="#/ny_aboutuser" @click="toLink(93)">{{
                basicData.basicList[5].key
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBasicMsg,
  getPageMsg,
  getAllXwData,
  getProductTypes,
  getCaseTypes,
  getZzCaseList
} from '@/api/api'
import Carousel from '@/common/Carousel/zzCarousel.vue'
import horseRace from '@/common/horseRace/horse_race.vue'
export default {
  components: {
    Carousel,
    horseRace
  },
  data () {
    return {
      li_xz: 0,
      imgList: {},
      basicData: {},
      cpList: [],
      newData: {
        link: 'ny_news',
        id: 92
      },
      qmList: [
        {
          img: 'http://img.iapply.cn/c083a933a3a4dea687beae510f777418',
          title: '乔木植物'
        },
        {
          img: 'http://img.iapply.cn/0a80d9b105d9d56c5220eb7e08b73c4a',
          title: '灌木植物'
        },
        {
          img: 'http://img.iapply.cn/74b83f19a26c4aca40456680a19b7f1e',
          title: '地被植物'
        },
        {
          img: 'http://img.iapply.cn/97a780943e5181ac3b5b8756adb61d3c',
          title: '球形植物'
        }
      ],
      ysList: [],
      xjData: {},
      alHead: [],
      alList: [],
      allNews: [],
      newsList: [],
      alData: {
        page: 1,
        pagesize: 3,
        type_id: '',
        project: ''
      }
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.imgList = res.data.data.allImg
              that.basicData = res.data.data.basic
              that.ysList = []
              that.ysList.push(res.data.data.优势)
              that.ysList.push(res.data.data.优势1)
              that.ysList.push(res.data.data.优势2)
              that.ysList.push(res.data.data.优势3)
              that.ysList.forEach((el, i) => {
                if (i == 0) {
                  el.ys_title = el.ys_title0
                  el.ys_logo = el.ys_logo0
                  el.ys_list = el.ys_list0
                } else if (i == 1) {
                  el.ys_bg = el.ys_bg1
                  el.ys_title = el.ys_title1
                  el.ys_logo = el.ys_logo1
                  el.ys_list = el.ys_list1
                } else if (i == 2) {
                  el.ys_bg = el.ys_bg2
                  el.ys_title = el.ys_title2
                  el.ys_logo = el.ys_logo2
                  el.ys_list = el.ys_list2
                } else if (i == 3) {
                  el.ys_bg = el.ys_bg3
                  el.ys_title = el.ys_title3
                  el.ys_logo = el.ys_logo3
                  el.ys_list = el.ys_list3
                }
              })
              that.xjData = res.data.data.细节
            }
          })
          getAllXwData(1, '', project).then(res => {
            if (res.data.code == 1) {
              that.allNews = res.data.data.list
              let arr = []
              res.data.data.list.forEach((val, i) => {
                if (i < 2) {
                  arr.push(val)
                }
              })
              that.newsList = arr
            }
          })
          that.alData.project = project
          getCaseTypes(project).then(res => {
            if (res.data.code == 1) {
              that.alHead = res.data.data
            }
          })
          that.qhAlData(that.alData)
          getProductTypes(project).then(res => {
            if (res.data.code == 1) {
              // res.data.data.forEach((e, i) => {
              //   if (e.id) {
              //     e.news = that.qmList
              //   }
              // })
              that.cpList = res.data.data
            }
          })
        }
      })
    },
    qhAlData (e) {
      getZzCaseList(e).then(res => {
        if (res.data.code == 1) {
          this.alList = res.data.data.list
        }
      })
    },
    replaceData (e, i) {
      this.li_xz = i
      this.alData.type_id = e.id
      this.qhAlData(this.alData)
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.ny_index {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  color: #212529;
  .all_title {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      width: 100px;
      height: 78px;
    }
    h4 {
      margin: 0px 0px 0px 50px;
      font-size: 36px;
      text-align: center;
      span {
        color: rgb(61, 160, 54);
        font-weight: 600;
      }
      p {
        display: block;
        font-size: 18px;
        color: rgb(136, 136, 136);
        margin-top: 15px;
      }
    }
  }
  .all_head {
    height: 180px;
    overflow: hidden;
  }
  .index_various {
    background-size: cover !important;
    padding: 50px 0px;
    .index_various_nav {
      width: 1200px;
      margin: 0px auto;
      .nav_qm {
        margin-bottom: 30px;
        width: 100%;
        .nav_qm_head {
          height: 63px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h4 {
            margin: 0px;
            display: inline-block;
            color: rgb(255, 255, 255);
            width: 188px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
          }
          span {
            margin-left: 60px;
            display: inline-block;
            font-size: 16px;
            color: rgb(100, 100, 100);
          }
          a {
            float: right;
            font-size: 16px;
          }
        }
        .nav_qm_list {
          border-width: 2px 1px 1px;
          border-style: solid;
          border-color: rgb(61, 160, 54) rgb(221, 221, 221) rgb(221, 221, 221);
          border-image: initial;
          background-color: rgb(255, 255, 255);
          margin-top: -2px;
          padding: 25px;
          display: flex;
          flex-wrap: wrap;
          li {
            width: calc(25% - 15px);
            margin-left: 20px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            img {
              padding: 2px;
              width: calc(100% - 4px);
              height: 200px;
            }
            p {
              margin: 0px;
              text-align: center;
              font-size: 16px;
              line-height: 2;
              background-color: rgb(221, 221, 221);
            }
          }
          li:nth-child(1) {
            margin-left: 0;
          }
          li:hover p {
            color: #3da036;
          }
        }
      }
    }
  }
  .index_section {
    padding: 70px 0 0;
    height: 400px;
    min-width: 1200px;
    .index_section_nav {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .nav_left {
        padding: 0px 72px 0px 105px;
        width: 500px;
        height: 330px;
        border-right: 1px solid rgb(221, 221, 221);
        img {
          width: 100%;
        }
      }
      .nav_right {
        width: 420px;
        margin-right: 40px;
        h2 {
          margin: 0px 0px 20px;
          line-height: 35px;
          background: url(~@/assets/image/all/line1.png) 0px bottom no-repeat;
          font-size: 20px;
          color: rgb(61, 160, 54);
          font-weight: 600;
        }
      }
    }
  }
  .index_advantage {
    max-width: 1600px;
    margin: 0 auto;
    margin-top: 60px;
    .index_advantage_nav {
      background-color: #eef7fe;
      padding: 50px 0;
      .nav_list {
        width: 1200px;
        margin: 0 auto;
        li {
          height: 450px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          background: no-repeat center 0;
          .li_tit {
            padding-top: 50px;
            display: flex;
            span {
              display: flex;
              margin-top: 8px;
              margin-left: 15px;
              font-size: 34px;
              padding: 20px 0 10px;
              color: #3da036;
              font-weight: bold;
            }
          }
          .li_text {
            padding-left: 110px;
            p {
              margin: 0;
              line-height: 38px;
              background: url(~@/assets/image/all/ys_ico.png) no-repeat 0 12px;
              padding-left: 20px;
              font-size: 16px;
              color: #666;
            }
          }
        }
        li:nth-child(2),
        li:nth-child(4) {
          align-items: flex-end;
          .li_tit {
            margin-right: 200px;
            padding-top: 100px;
          }
          .li_text {
            margin-right: 80px;
          }
        }
      }
    }
  }
  .index_nicety {
    max-width: 1600px;
    margin: 0 auto;
    .index_nicety_nav {
      padding: 50px 0;
      .nav_list {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        li {
          width: calc(20% - 2px);
          margin: 0 1px;
          background-color: #e4e4e4;
          cursor: pointer;
          .li_img {
            padding: 4px;
            height: 170px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .li_text {
            padding: 0 20px 15px;
            h2 {
              margin: 0;
              line-height: 2.5;
              font-size: 18px;
              text-align: center;
              font-weight: 600;
            }
            p {
              margin: 0;
              line-height: 1.5;
              overflow: hidden;
              text-align: justify;
              padding-top: 10px;
              font-size: 14px;
              border-top: 1px solid #000;
            }
          }
        }
        li:hover {
          color: #fff;
          background-color: #3da036;
          p {
            border-top: 1px solid #fff;
          }
        }
      }
    }
    .index_nicety_case {
      padding: 50px 0;
      width: 1200px;
      margin: 0 auto;
      .case_head {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        a {
          display: inline-block;
          margin: 0px 1px;
          font-size: 18px;
          width: 248px;
          background-color: #3da036;
          line-height: 50px;
          color: rgb(255, 255, 255);
          border-radius: 3px;
          text-align: center;
        }
        a:hover {
          background-color: #fb2c3b;
        }
      }
      .item_nav {
        a {
          border: 3px solid rgb(61, 160, 54);
          position: relative;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .li_text {
          padding: 15px 0;
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  .index_base {
    .index_base_nav {
      padding: 50px 0;
      width: 1200px;
      margin: 0 auto;
    }
    .news {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .news_img {
        width: 45%;
      }
      .news_list {
        width: 45%;
        li {
          border: 1px solid #ddd;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          .li_nav {
            width: 65%;
            a {
              font-weight: 600;
              height: 15px;
              width: 100%;
              display: inline-block;
            }
            a:hover {
              color: #3da036;
            }
            h4 {
              margin-top: 14px;
              margin-bottom: 10px;
            }
            p {
              line-height: 1.6;
              -webkit-line-clamp: 3;
            }
          }
          .li_img {
            width: 32%;
            img {
              width: 100%;
            }
          }
        }
        li:nth-child(2) {
          margin-top: 20px;
        }
      }
    }
    .aboutUs {
      display: flex;
      margin-top: 50px;
      img {
        width: 100%;
      }
      .aboutUs_img {
        width: 60%;
      }
      .aboutUs_nav {
        width: 40%;
        .aboutUs_nav_head {
          width: 100%;
          height: 26%;
        }
        .aboutUs_nav_col {
          background-color: #f2f2f2;
          height: 74%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #333;
          p {
            padding: 0 30px;
            font-size: 16px;
            text-indent: 32px;
            line-height: 1.8;
            text-align: justify;
          }
          a {
            display: block;
            width: 140px;
            line-height: 40px;
            margin: 50px auto 0px;
            font-size: 16px;
            background-color: rgb(61, 160, 54);
            color: rgb(255, 255, 255);
            text-align: center;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>
